import { ItemStatuses } from 'constants/statuses';
import { PetData } from 'flows/resident-onboarding/constants/types';
import {
  isPetsAbandoned,
  isPetsAllAnswersComplete,
} from 'flows/resident-onboarding/utils/pets-metadata';
import { BadgeConfig } from '../../types/cards';
import { useRESTClient } from 'context';
import { useEffect, useState } from 'react';

export const enabledItemDefOfKind = (itemCode: string | string[]) => {
  const codes = Array.isArray(itemCode) ? itemCode : [itemCode];
  return ({ code, enabled }: { code: string; enabled: boolean }) =>
    codes.includes(code) && enabled;
};

export const isPetsAbandonedOrAllAnswersComplete = (
  petsData: Array<PetData>,
  status: string
) =>
  isPetsAbandoned(petsData) ||
  (isPetsAllAnswersComplete(petsData) && status === ItemStatuses.STARTED);

export const enabledItemOfId = (itemId: number) => {
  return ({ id }: { id: number }) => itemId === id;
};

export const itemOfKind = (itemKind: string | string[]) => {
  const kinds = Array.isArray(itemKind) ? itemKind : [itemKind];
  return ({ kind }: { kind: string }) => kinds.includes(kind);
};

export const itemHasPrefix = (itemPrefix: string) => {
  return ({ kind }: { kind: string }) => kind.includes(itemPrefix);
};

export const statusIsOneOf = (statusArray: string[]) => {
  return (obj: unknown) => {
    if (typeof obj === 'object' && obj !== null && 'status' in obj) {
      return statusArray.some((statusItem) => statusItem === obj.status);
    }
    return false;
  };
};

export const statusIs = (statusName: string) => {
  return statusIsOneOf([statusName]);
};

export const isCompleted = statusIs(ItemStatuses.COMPLETED);

export const isFYMCompleted = statusIsOneOf([
  ItemStatuses.COMPLETED,
  ItemStatuses.VALIDATING,
]);

export const isTVICompleted = statusIsOneOf([
  ItemStatuses.COMPLETED,
  ItemStatuses.VALIDATING,
  ItemStatuses.PENDING,
]);

export const isTaskCompletedOrValidating = statusIsOneOf([
  ItemStatuses.COMPLETED,
  ItemStatuses.VALIDATING,
]);

export const isTaskRejected = statusIs(ItemStatuses.INFO_REQUESTED);

type BadgeStatus =
  | 'DONE'
  | 'IN_REVIEW'
  | 'RECENTLY_VIEWED'
  | 'ALMOST_DONE'
  | 'CANCELLED'
  | 'NEEDS_MORE'
  | 'UPDATED'
  | 'REQUIRED';

export const Badges: Record<BadgeStatus, BadgeConfig> = {
  DONE: {
    variant: 'done',
    text: 'Done',
  },
  IN_REVIEW: {
    variant: 'pending',
    text: 'In review',
  },
  RECENTLY_VIEWED: {
    variant: 'info',
    text: 'Recently viewed',
  },
  ALMOST_DONE: {
    variant: 'info',
    text: 'Almost done',
  },
  CANCELLED: {
    variant: 'cancelled',
    text: 'Cancelled',
  },
  NEEDS_MORE: {
    variant: 'warning',
    text: 'Needs more',
  },
  UPDATED: {
    variant: 'info',
    text: 'Updated',
  },
  REQUIRED: {
    variant: 'required',
    text: 'Required',
  },
};

export const ImportanceScoreConfig = {
  FYM: {
    unverified: 750_001,
    completed: -2_000_000,
    default: 100_000,
  },
  MYS: {
    started: 750_001,
    default: 1_000_000,
  },
  PET: {
    default: 400_000,
    started: 1_150_001,
    validating: 0,
    completed: 0,
    info_requested: 2_100_000,
  },
  CAR: {
    default: 500_000,
    started: 1_250_001,
    validating: 0,
    completed: 0,
    info_requested: 2_200_000,
  },
  ROB_UTILITY: {
    default: 800_000,
    started: 1_550_001,
    info_requested: 2_300_000,
    validating: 0,
    completed: 0,
  },
  MOVE_IN_COSTS: {
    default: 1_070_000,
    started: 1_820_001,
    validating: 0,
    info_requested: 2_500_000,
    completed: 0,
  },
  INSURANCE: {
    default: 1_005_000,
    started: 1_755_001,
    validating: 0,
    info_requested: 2_400_000,
    completed: 0,
  },
} as const;

export const lookupImportanceByCardStatus = (
  cardType: keyof typeof ImportanceScoreConfig,
  status?: string
): number => {
  const config = ImportanceScoreConfig[cardType];
  return config[status as keyof typeof config] ?? config.default;
};

interface CheckFlowStatusProps {
  moveId: string | undefined;
}

export const useCheckFlowStatus = ({ moveId }: CheckFlowStatusProps) => {
  const [loading, setLoading] = useState(false);
  const [completedFlows, setCompletedFlows] = useState<string[]>([]);

  const { axios } = useRESTClient();

  useEffect(() => {
    const fetchResult = async (moveId: string) => {
      setLoading(true);
      const result = axios.get(`/v2/moves/${moveId}`).then((res) => {
        const completed = res?.data?.declined_item_types?.already_done; //?.indexOf('utility') > -1;
        setCompletedFlows(completed);
        setLoading(false);
        return [completed, loading];
      });
      return result;
    };

    if (moveId) {
      fetchResult(moveId);
      setLoading(false);
    }
  }, [axios, loading, moveId]);

  return {
    loading,
    completedFlows,
  };
};

import { CardData, CardDataWithHeroExtras } from 'flows/core/types/cards';
import { ItemKinds } from 'types';
import { DataForCards } from 'flows/core/hooks/use-data-for-cards';
import { EssentialCards } from 'flows/core/types/layouts';
import { Badges, enabledItemDefOfKind, isCompleted, itemOfKind } from './utils';

/* Static Properties */
export const STATIC_LOGOS = [
  {
    url: '/images/util-providers/consolidated-edison.svg',
    alt: 'conEdison',
  },
  {
    url: '/images/util-providers/ng-logo.svg',
    alt: 'National Grid',
  },
  {
    url: '/images/util-providers/nyc-dep-logo.png',
    alt: 'NYC environmental protection',
  },
];

const KIND = ItemKinds.UTILITY;
const ROB_UTILITY_KINDS = [
  ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_WATER,
  ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_ELECTRIC,
  ItemKinds.RESIDENT_ONBOARDING_PROOF_OF_GAS,
];

const UTILITY_COMMON_STATIC_DATA = {
  subtype: 'UTIL',
  icon: 'LIGHT',
  title: 'Connect utilities',
  action: {
    type: 'internal',
    route: 'utilities',
  },
} as const;

const UTILITY_PROMO_STATIC_DATA = {
  ...UTILITY_COMMON_STATIC_DATA,
  providers: STATIC_LOGOS,
} as const;

export const makeTodayTabUtilityCards = ({
  itemDefinitions,
}: DataForCards): CardDataWithHeroExtras[] => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const hasROBUtilities = itemDefinitions.find(
    enabledItemDefOfKind(ROB_UTILITY_KINDS)
  );

  if (itemDef && !hasROBUtilities) {
    return [
      {
        type: 'PROMOTED',
        data: UTILITY_PROMO_STATIC_DATA,
        heroExtras: {
          ctaText: 'Get started',
          heading: 'Check utility providers and get set up on day one.',
        },
        identifier: 'utilities_initiate',
        importanceScore: 250_000,
      },
    ];
  }
  return [];
};

export const makeEssentialsTabUtilityCards = ({
  itemDefinitions,
  items,
  isUPO,
  completedFlows,
}: DataForCards & {
  isUPO: boolean;
  completedFlows: string[];
}): EssentialCards => {
  // Arrays to be mutated
  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];

  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const hasROBUtilities = itemDefinitions.find(
    enabledItemDefOfKind(ROB_UTILITY_KINDS)
  );
  const utilityItems = items.filter(itemOfKind(KIND));
  const utilitiesComplete = completedFlows?.includes('utility');

  //Different static text for UPO and nonUPO
  const description = isUPO
    ? 'Review service providers or confirm you’ve already done this.'
    : 'Review your service providers in one place to set up the essentials.';

  if (itemDef && !hasROBUtilities) {
    const card: CardData = {
      importanceScore: 250_000,
      type: 'INITIATE',
      data: { ...UTILITY_COMMON_STATIC_DATA, description },
      identifier: '',
    };

    if (utilityItems.length === 0) {
      if (utilitiesComplete) {
        card.data.badge = Badges.DONE;
        card.identifier = 'util_initiate_completed';
        completedCards.push(card);
      } else {
        card.identifier = 'util_initiate';
        toDoCards.push(card);
      }
    } else {
      utilityItems.forEach((utilityItem: { id: string; status: string }) => {
        if (utilityItem.status && isCompleted(utilityItem)) {
          card.identifier = `util_initiate_completed_${utilityItem.id}`;
          completedCards.push(card);
        } else {
          card.identifier = `util_initiate_${utilityItem.id}`;
          toDoCards.push(card);
        }
      });
    }
  }

  return {
    toDoCards,
    completedCards,
  };
};

import {
  CardDataWithHeroExtras,
  BadgeConfig,
  CardData,
} from 'flows/core/types/cards';
import { ItemKinds } from 'types';
import { DataForCards } from 'flows/core/hooks/use-data-for-cards';
import sortBy from 'lodash/sortBy';
import { dollyMove } from 'types/generated/dollyMove';
import {
  enabledItemDefOfKind,
  itemOfKind,
  Badges,
  lookupImportanceByCardStatus,
} from './utils';

const mysPromoDraftMap = '/images/mys-promo-draft-map.svg';
const mysPromoMap = '/images/mys-promo-map.svg';

const KIND = ItemKinds.MOVE_YOUR_STUFF;

const MYS_STATIC_DATA = {
  subtype: 'IMAGE',
  icon: 'TRUCK',
} as const;

const MYS_INITIATE_PROMO_DATA = {
  ...MYS_STATIC_DATA,
  title: 'Move your stuff',
  image: {
    url: mysPromoMap,
    alt: 'mys-initiate-promo',
  },
};

const MYS_DRAFT_PROMO_DATA = {
  ...MYS_STATIC_DATA,
  title: 'Finish booking movers',
  image: {
    url: mysPromoDraftMap,
    alt: 'mys-draft-promo',
  },
} as const;

const MYS_HERO_EXTRAS = {
  initiate: {
    heading: 'Get help moving a couch, or your whole home.',
    ctaText: 'Book movers',
  },
  draft: {
    heading: 'So close! Get your movers lined up.',
    ctaText: 'Book movers',
  },
} as const;

const statusToImportance = (status?: string) => {
  if (status === 'started') {
    return 1_500_001;
  }
  if (status === 'completed') {
    return 0;
  }
  return 750_000;
};

const statusToBadge = (status?: string): BadgeConfig | undefined => {
  if (status === 'started' || status === 'pending') {
    return Badges.RECENTLY_VIEWED;
  }
  if (status === 'reserved') {
    return Badges.ALMOST_DONE;
  }
  if (status === 'cancelled') {
    return Badges.CANCELLED;
  }
  if (status === 'completed' || status === 'validating') {
    return Badges.DONE;
  }
  return undefined;
};

const makeDraftMovingCards = ({ items }) => {
  const mysItems = sortBy(items.filter(itemOfKind(KIND)), 'createdAt');
  const mysDraftCards: CardData[] = mysItems
    .map((item) => {
      if (['completed', 'validating'].includes(item.status)) {
        return undefined;
      }
      return {
        type: 'PROMOTED' as const,
        data: {
          ...MYS_DRAFT_PROMO_DATA,
          action: {
            type: 'internal' as const,
            route: `/move-your-stuff/resume/${item.id}`,
          },
          extraContent: [
            {
              type: 'AddressLine' as const,
              content: {
                point: 'From',
                address: item.metadata?.from_address?.split(',')[0],
              },
              key: 'fromAddress',
            },
            {
              type: 'AddressLine' as const,
              content: {
                point: 'To',
                address: item.metadata?.to_address?.split(',')[0],
              },
              key: 'toAddress',
            },
          ],
          badge: statusToBadge(item.status),
        },
        identifier: `dolly_draft_${item.id}`,
        importanceScore: lookupImportanceByCardStatus('MYS', item.status),
      };
    })
    .filter(Boolean);
  return mysDraftCards;
};

export const makeTodayTabMoveYourStuffCards = (
  { itemDefinitions, items }: DataForCards,
  dolly?: dollyMove
): CardDataWithHeroExtras[] => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const moveYourStuffItems = items.filter(itemOfKind(KIND));

  if (itemDef && dolly?.dollyMove.eligible) {
    if (moveYourStuffItems.length === 0) {
      return [
        {
          type: 'PROMOTED',
          data: {
            ...MYS_INITIATE_PROMO_DATA,
            action: {
              type: 'internal',
              route: '/moving',
            },
          },
          heroExtras: MYS_HERO_EXTRAS.initiate,
          identifier: 'mys_promoted',
          importanceScore: statusToImportance(moveYourStuffItems[0]?.status),
        },
      ];
    }
    return makeDraftMovingCards({ items }).map((mysDraftCard) => ({
      ...mysDraftCard,
      heroExtras: MYS_HERO_EXTRAS.draft,
    }));
  }
  return [];
};

export const makeMovingTabDraftMoveYourStuffCards = ({
  items,
}: DataForCards): CardData[] => {
  return makeDraftMovingCards({ items });
};
